
                    @import '~@fairstone/style/themes/fairstone/abstracts/variables';
                    @import '~@fairstone/style/themes/fairstone/abstracts/mixins';
                
.sessionDialog {
    max-width: 55rem;
    margin: auto;
    box-shadow: none;

    .titleContainer {
        text-align: center;

        .icon {
            height: 9.6rem;
            width: 9.6rem;
            margin-bottom: 2rem;
        }
    }

    .center {
        text-align: center;
    }

    .centerBtn {
        margin: auto;
    }
}